* {
  font-family: 'Montserrat', sans-serif;

}

@import url('https://fonts.cdnfonts.com/css/montserrat');
@import url('https://fonts.cdnfonts.com/css/visitor');
@import url('https://fonts.cdnfonts.com/css/kg-the-last-time');


@media screen and (max-width: 499px) {
  .placeAndWL{
    display: none;
  }
  .navBar {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .discord4 {
    display: none;
  }

  /*.............. FAQ ..............*/

  summary {
    font-size: 30px;
    font-weight: 600;
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;

    border: 2px solid #414141;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 70%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .faq__content {

    color: rgba(255, 255, 255, 0.829);
    padding-bottom: 20px;
    text-align: left;
    font-size: 25px;
  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 90px;
    color: white;
    text-shadow: 0 0 5px #ffffff;
  }

  .faqbg {
    width: 100%;
    text-align: left;
    padding: 2px;
    padding-bottom: 20px;
    margin-right: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: 100%;
    padding-top: 5%;
    padding-bottom: 10%;
  }

  .writebtn {
    background-color: #53535385;
    color: white;
    padding: 10px;
    border-radius: 10px;
    border: none;
  }


  .mintingAmount {
    font-size: 20px;
    padding: 10px;

  }

  .adH {
    font-size: 40px;
    color: white;
    text-align: center;
    font-weight: bold;
  }

  .boxWrap6 {
    margin-top: 5%;
  }

  .osPic {
    width: 30px;
    height: 30px;
    cursor: pointer;

  }

  .price {
    text-align: center;
    font-size: 23px;
    color: #ffffff;
  }

  .discord2 {
    display: none;
  }

  .discord3 {
    padding-right: 20px;
    display: none;
  }


  .uRMobile {
    display: none;
  }

  .storyPicDivMobile {
    display: none;
  }

  .allWrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
  }

  .boxWrap2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #0000003f;
    padding-top: 5%;
    padding-bottom: 5%;
    border-radius: 20%;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .boxWrap3 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 10%;
    margin-bottom: 10%;
  }

  .storyPicDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .storyH {
    font-size: 50px;
    font-weight: bold;
    text-shadow: 4px 3px 0 #383d6e62;
    color: white;
    margin-bottom: 10px;
    margin-top: 10%;
  }

  .storyCon {
    color: white;
    font-size: 22px;
    text-shadow: 0 0 10px #ffffff4b;
  }

  .storyPic {
    width: 200px;
    height: 250px;
    border: 2px solid #d423b7;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 10px #d64dbf;
  }

  .storyRight {
    width: 90%;
    text-align: center;
    margin-top: 5%;
    margin-left: auto;
    margin-right: auto;
  }

  .uR {
    width: 50%;
  }

  .intro {
    color: white;
    font-size: 40px;
    letter-spacing: 7px;
    text-align: center;
    margin-bottom: 5%;
    font-family: 'Russo One', sans-serif;

  }

  .intro2 {
    color: rgba(255, 255, 255, 0.918);
    font-size: 20px;
    text-shadow: 4px 3px 0 #383d6e18;
    margin-top: -5%;
    line-height: 1.5;
    text-align: center;
  }

  .mintDiv {

    width: 95%;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 5px dashed white;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10%;
    margin-top: 5%;
  }

  .errorMessage {

    font-size: 20px;
    color: #fc534d;
    text-align: center;

  }

  .loadingContainer {}

  .loadingText {
    font-size: 25px;
    text-align: center;
    color: rgb(204, 204, 204);
  }

  .loadTextSub {
    text-align: center;
    color: rgb(185, 185, 185);
    font-size: 13px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;

  }

  .wPMobile {
    display: none;
  }

  .introductionMobile {
    display: none
  }

  .hl {
    color: #6ce34d;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 20px;
    padding-top: 20px;
    justify-content: space-evenly;
    width: max-content;
    margin-left: auto;
    margin-right: auto;
  }

  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-left: auto;
    margin-right: auto;
    width: 5%;
    justify-content: space-between;
  }

  .discord {
    padding-right: 20px;
    transition: transform .2s;
    margin-top: auto;
    margin-bottom: auto;
  }

  .discord:hover {
    transform: scale(1.1);
  }


  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }



  .note {
    color: rgb(255, 255, 255);
  }

  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;

  }

  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/

  }

  /*.website{
  margin-top: 10px;
  margin-left: 10px;
 /* z-index: -2;*/
  /*color: #ffffffa2;
}*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;

  }





  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;

    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);


  }

  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: 'Poppins', sans-serif;
    padding-top: 5px;
    margin-left: 10%;

  }

  .connect2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-top: 5px;

  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Poppins', sans-serif;
    color: white;
    padding-top: 15px;
    font-size: 16px;


  }

  .connect div:hover {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Poppins', sans-serif;
    color: rgb(184, 184, 184);
    padding-top: 15px;
    cursor: pointer;

  }


  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }




  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;

  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    height: max-content;
  }

  .nftminter2 {
    color: yellow;
    font-size: 75px;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    justify-content: center;
  }

  .nftminter {
    background-color: #18181a;
    justify-content: flex-start;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    width: 50px;
    border: none;
    cursor: pointer;
    border: 2px solid #620fb5;

  }

  .btnfos-0-2-2 {
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    width: 50px;
    border: none;
    cursor: pointer;
    border: 2px solid #620fb5;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }


  .btnfos-0-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) -5px 5px;
  }


  .btnfos-0-2-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) 5px 5px;
  }


  .btnfos-0-3 {
    font-size: 30px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 5px;
    border: 2px solid #620fb5;

    justify-content: center;
    text-align: center;
    width: 250px;
    margin-bottom: 5%;
  }

  .btnfos-0-4 {
    font-size: 30px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 5px;
    border: 2px solid rgb(255, 255, 255);
    background-color: #620fb5;
    justify-content: center;
    text-align: center;
    width: fit-content;
    margin-bottom: 5%;
    margin-top: 5%;
    color: white;
  }


  .btnfos-0-3:hover {

    color: rgb(78, 78, 78);
    cursor: pointer;

  }

  .btnfos-0-3:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(141, 141, 141, 0.288) 0px 5px;
  }

  .nftamount {
    color: rgb(255, 174, 0);
    font-size: 120px;
    text-shadow: 4px 3px 0 #383d6e17;
  }

  .walletConnect {
    display: flex;
    margin-top: 2%;
    margin-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .connectButton {

    font-size: 45px;
  }

  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;

  }

  .loadingContainer {
    text-align: center;
  }

  .successfully {
    text-align: center;
    color: rgb(124, 199, 74);
    font-size: 25px;
  }


  .headers {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding-top: 12px;
    margin-left: auto;
    margin-right: auto;

    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/

  }

  .headers2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-bottom: 12px;
    padding-top: 12px;
    width: 90%;

    margin-left: auto;
    margin-right: auto;


    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/

  }


  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-top: 2%;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .wallet2 {
    width: 230px;
    padding: 5px;
    transition: transform .3s;
    font-size: 20px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    background-color: #efb03a00;
     border: 2px solid #ac01ac;
    color: white;
  }

  .wallet2:hover {
        background-color: rgba(255, 255, 255, 0.103);

  }

  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }

  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }


  .wallet3 {

    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    padding-left: 50px;
    padding-right: 50px;
    align-items: center;
    background: rgb(92, 1, 91);
    background: linear-gradient(354deg, rgba(92, 1, 91, 1) 1%, rgba(75, 12, 140, 1) 61%);
    border: 0;
    box-sizing: border-box;
    box-sizing: border-box;
    color: rgb(255, 255, 255);
    display: flex;
    transition: transform .5s;
    font-family: 'Russo One', sans-serif;


  }

  .wallet3:hover {
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
    transform: scale(1.2);
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  .in2 {
    width: 100%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }

  .totalSupply {
    text-align: center;
    font-size: 50px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    text-shadow: 4px 3px 0 #383d6e3a;
    letter-spacing: 1px;
    font-family: 'Russo One', sans-serif;
  }

  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }

    10%,
    87.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    20%,
    92.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    25%,
    95% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  .logoPic {
    border-radius: 50%;
    display: none;
    width: 100px;
    height: 100px;
    border: 2px solid #7212c0;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 10px #780594;
  }

  .logo {
    margin-top: auto;
    margin-bottom: auto;
  }

  .footer {
    background-color: rgba(0, 0, 0, 0);
    padding: 2%;
    position: static;
    margin-top: 10%;
  }

  .copyright {
    color: rgba(255, 255, 255, 0.712);
    text-align: center;
    font-size: 50px;
    text-decoration: underline;
  }

  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }

  .light2 {
    height: 200vh;
  }

  .light {
    background: radial-gradient(circle, rgba(0, 0, 0, 0.6642254313834909) 1%, rgba(0, 0, 0, 0.9471385966495973) 49%, rgba(0, 0, 0, 0.9247296330641632) 100%), url('./assets/bg.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100vh;
    height: max-content;
    overflow: hidden;
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }

    100% {
      transform: translateX(0);
    }
  }

  @keyframes Slide_Left_Right {

    0%,
    100% {
      transform: translateX(100px);
    }

    12.5%,
    75% {
      transform: translateX(0);
    }

    25% {
      transform: translateX(20px);
    }

  }

  @keyframes Opacity {

    0%,
    100% {
      opacity: 0;
    }

    25%,
    75% {
      opacity: 1;
    }


  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }

  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }

    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }

    50% {
      transform: scale(1.05, 1.05);
    }

    100% {
      transform: scale(1, 1);
    }
  }

  .beast {
    width: 300px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
    border: white 2px solid;
    border: 2px solid #7212c0;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 10px #780594;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 5%;
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }

    50% {
      transform: scale(1.05, 1.05);
    }

    100% {
      transform: scale(1, 1);
    }
  }

  /* CSS */
  .button-57 {
    position: relative;
    overflow: hidden;
    border: 1px solid #18181a;
    color: #ffffff;
    display: inline-block;
    font-size: 70px;
    line-height: 15px;
    padding: 50px 50px 50px;
    text-decoration: none;
    cursor: pointer;
    background: rgba(240, 97, 221, 0.171);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    letter-spacing: 3px;

  }

  .button-57 span:first-child {
    position: relative;
    transition: color 600ms cubic-bezier(0.48, 0, 0.12, 1);
    z-index: 10;
  }

  .button-57 span:last-child {
    color: white;
    display: flex;
    position: absolute;
    bottom: 0;
    transition: all 500ms cubic-bezier(0.48, 0, 0.12, 1);
    z-index: 100;
    opacity: 0;
    top: 50%;
    left: 50%;
    transform: translateY(225%) translateX(-50%);
    height: 30px;
    line-height: 30px;
  }

  .button-57:after {
    content: "";
    position: absolute;
    bottom: -50%;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    transform-origin: bottom center;
    transition: transform 600ms cubic-bezier(0.48, 0, 0.12, 1);
    transform: skewY(9.3deg) scaleY(0);
    z-index: 50;
  }

  .button-57:hover:after {
    transform-origin: bottom center;
    transform: skewY(9.3deg) scaleY(2);
  }

  .button-57:hover span:last-child {
    transform: translateX(-50%) translateY(-100%);
    opacity: 1;
    transition: all 900ms cubic-bezier(0.48, 0, 0.12, 1);
  }
}

@media screen and (min-width: 500px) and (max-width: 767px) {
  .placeAndWL{
    display: none;
  }
  .discord4 {
    display: none;
  }

  /*.............. FAQ ..............*/

  summary {
    font-size: 30px;
    font-weight: 600;
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;

    border: 2px solid #414141;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 70%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .faq__content {

    color: rgba(255, 255, 255, 0.829);
    padding-bottom: 20px;
    text-align: left;
    font-size: 25px;
  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 90px;
    color: white;
    text-shadow: 0 0 5px #ffffff;
  }

  .faqbg {
    width: 100%;
    text-align: left;
    padding: 2px;
    padding-bottom: 20px;
    margin-right: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: 100%;
    padding-top: 5%;
    padding-bottom: 10%;
  }

  .writebtn {
    background-color: #53535385;
    color: white;
    padding: 10px;
    border-radius: 10px;
    border: none;
  }


  .mintingAmount {
    font-size: 20px;
    padding: 10px;

  }

  .adH {
    font-size: 40px;
    color: white;
    text-align: center;
    font-weight: bold;
  }

  .boxWrap6 {
    margin-top: 5%;
  }

  .osPic {
    width: 30px;
    height: 30px;
    cursor: pointer;

  }

  .price {
    text-align: center;
    font-size: 32px;
    color: #ffffff;
  }

  .discord2 {
    display: none;
  }

  .discord3 {
    padding-right: 20px;
    display: none;
  }


  .uRMobile {
    display: none;
  }

  .storyPicDivMobile {
    display: none;
  }

  .allWrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
  }

  .boxWrap2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #0000003f;
    padding-top: 5%;
    padding-bottom: 5%;
    border-radius: 20%;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .boxWrap3 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 10%;
    margin-bottom: 10%;
  }

  .storyPicDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .storyH {
    font-size: 50px;
    font-weight: bold;
    text-shadow: 4px 3px 0 #383d6e62;
    color: white;
    margin-bottom: 10px;
    margin-top: 10%;
  }

  .storyCon {
    color: white;
    font-size: 22px;
    text-shadow: 0 0 10px #ffffff4b;
  }

  .storyPic {
    width: 200px;
    height: 250px;
    border: 2px solid #d423b7;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 10px #d64dbf;
  }

  .storyRight {
    width: 90%;
    text-align: center;
    margin-top: 5%;
    margin-left: auto;
    margin-right: auto;
  }

  .uR {
    width: 50%;
  }

  .intro {
    color: white;
    font-family: 'Russo One', sans-serif;
    text-align: center;
    margin-bottom: 5%;
    font-size: 50px;
  }

  .intro2 {
    color: rgba(255, 255, 255, 0.918);
    font-size: 20px;
    text-shadow: 4px 3px 0 #383d6e18;
    margin-top: -5%;
    line-height: 1.5;
    text-align: center;
  }

  .mintDiv {

    width: 90%;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 5px dashed white;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10%;
    margin-top: 5%;

  }

  .errorMessage {

    font-size: 20px;
    color: #fc534d;
    text-align: center;

  }

  .loadingContainer {}

  .loadingText {
    font-size: 25px;
    text-align: center;
    color: rgb(204, 204, 204);
  }

  .loadTextSub {
    text-align: center;
    color: rgb(185, 185, 185);
    font-size: 13px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;

  }

  .wPMobile {
    display: none;
  }

  .introductionMobile {
    display: none
  }

  .hl {
    color: #6ce34d;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 20px;
    padding-top: 20px;
    justify-content: space-evenly;
    width: max-content;
    margin-left: auto;
    margin-right: auto;
  }

  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-left: auto;
    margin-right: auto;
    width: 5%;
    justify-content: space-between;
  }

  .discord {
    padding-right: 20px;
    transition: transform .2s;
    margin-top: auto;
    margin-bottom: auto;
  }

  .discord:hover {
    transform: scale(1.1);
  }


  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }



  .note {
    color: rgb(255, 255, 255);
  }

  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;

  }

  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/

  }

  /*.website{
  margin-top: 10px;
  margin-left: 10px;
 /* z-index: -2;*/
  /*color: #ffffffa2;
}*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;

  }





  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;

    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);


  }

  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: 'Poppins', sans-serif;
    padding-top: 5px;
    margin-left: 10%;

  }

  .connect2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-top: 5px;

  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Poppins', sans-serif;
    color: white;
    padding-top: 15px;
    font-size: 16px;


  }

  .connect div:hover {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Poppins', sans-serif;
    color: rgb(184, 184, 184);
    padding-top: 15px;
    cursor: pointer;

  }


  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }




  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;

  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    height: max-content;
  }

  .nftminter2 {
    color: yellow;
    font-size: 75px;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    justify-content: center;
  }

  .nftminter {
    background-color: #18181a;
    justify-content: flex-start;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    width: 50px;
    border: none;
    cursor: pointer;
    border: 2px solid #620fb5;

  }

  .btnfos-0-2-2 {
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    width: 50px;
    border: none;
    cursor: pointer;
    border: 2px solid #620fb5;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }


  .btnfos-0-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) -5px 5px;
  }


  .btnfos-0-2-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) 5px 5px;
  }


  .btnfos-0-3 {
    font-size: 30px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 5px;
    border: 2px solid #620fb5;

    justify-content: center;
    text-align: center;
    width: 250px;
    margin-bottom: 5%;
  }

  .btnfos-0-4 {
    font-size: 30px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 5px;
    border: 2px solid rgb(255, 255, 255);
    background-color: #620fb5;
    justify-content: center;
    text-align: center;
    width: fit-content;
    margin-bottom: 5%;
    margin-top: 5%;
    color: white;
  }


  .btnfos-0-3:hover {

    color: rgb(78, 78, 78);
    cursor: pointer;

  }

  .btnfos-0-3:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(141, 141, 141, 0.288) 0px 5px;
  }

  .nftamount {
    color: rgb(255, 174, 0);
    font-size: 120px;
    text-shadow: 4px 3px 0 #383d6e17;
  }

  .walletConnect {
    display: flex;
    margin-top: 2%;
    margin-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .connectButton {

    font-size: 45px;
  }

  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;

  }

  .loadingContainer {
    text-align: center;
  }

  .successfully {
    text-align: center;
    color: rgb(124, 199, 74);
    font-size: 25px;
  }


  .headers {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding-top: 12px;
    margin-left: auto;
    margin-right: auto;

    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/

  }

  .headers2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-bottom: 12px;
    padding-top: 12px;
    width: 90%;

    margin-left: auto;
    margin-right: auto;


    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/

  }


  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-top: 2%;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .wallet2 {
    width: 230px;
    padding: 5px;
    transition: transform .3s;
    font-size: 20px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    background-color: #efb03a00;
    border: 2px solid #ac01ac;

    color: white;
  }

  .wallet2:hover {
        background-color: rgba(255, 255, 255, 0.103);

  }

  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }

  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }


  .wallet3 {

    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 40px;
    padding-left: 50px;
    padding-right: 50px;
    align-items: center;
    background: rgb(92, 1, 91);
    background: linear-gradient(354deg, rgba(92, 1, 91, 1) 1%, rgba(75, 12, 140, 1) 61%);
    border: 0;
    box-sizing: border-box;
    box-sizing: border-box;
    color: white;
    display: flex;
    transition: transform .5s;
    font-family: 'Russo One', sans-serif;

  }

  .wallet3:hover {
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
    transform: scale(1.2);
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  .in2 {
    width: 90%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }

  .totalSupply {
    text-align: center;
    font-size: 50px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    text-shadow: 4px 3px 0 #383d6e3a;
    letter-spacing: 1px;
    font-family: 'Russo One', sans-serif;
  }

  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }

    10%,
    87.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    20%,
    92.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    25%,
    95% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  .logoPic {
    border-radius: 50%;
    display: none;
    width: 100px;
    height: 100px;
    border: 2px solid #7212c0;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 10px #780594;
  }

  .logo {
    margin-top: auto;
    margin-bottom: auto;
  }

  .footer {
    background-color: rgba(0, 0, 0, 0);
    padding: 2%;
    position: static;
    margin-top: 10%;
  }

  .copyright {
    color: rgba(255, 255, 255, 0.712);
    text-align: center;
    font-size: 50px;
    text-decoration: underline;
  }

  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }

  .light2 {
    height: 200vh;
  }

  .light {
    background: radial-gradient(circle, rgba(0, 0, 0, 0.6642254313834909) 1%, rgba(0, 0, 0, 0.9471385966495973) 49%, rgba(0, 0, 0, 0.9247296330641632) 100%), url('./assets/bg.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100vh;
    height: max-content;
    overflow: hidden;
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }

    100% {
      transform: translateX(0);
    }
  }

  @keyframes Slide_Left_Right {

    0%,
    100% {
      transform: translateX(100px);
    }

    12.5%,
    75% {
      transform: translateX(0);
    }

    25% {
      transform: translateX(20px);
    }

  }

  @keyframes Opacity {

    0%,
    100% {
      opacity: 0;
    }

    25%,
    75% {
      opacity: 1;
    }


  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }

  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }

    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }

    50% {
      transform: scale(1.05, 1.05);
    }

    100% {
      transform: scale(1, 1);
    }
  }

  .beast {
    width: 400px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
    border: white 2px solid;
    border: 2px solid #7212c0;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 10px #780594;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 5%;

  }

  .navBar {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }

    50% {
      transform: scale(1.05, 1.05);
    }

    100% {
      transform: scale(1, 1);
    }
  }

  /* CSS */
  .button-57 {
    position: relative;
    overflow: hidden;
    border: 1px solid #18181a;
    color: #ffffff;
    display: inline-block;
    font-size: 70px;
    line-height: 15px;
    padding: 50px 50px 50px;
    text-decoration: none;
    cursor: pointer;
    background: rgba(240, 97, 221, 0.171);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    letter-spacing: 3px;

  }

  .button-57 span:first-child {
    position: relative;
    transition: color 600ms cubic-bezier(0.48, 0, 0.12, 1);
    z-index: 10;
  }

  .button-57 span:last-child {
    color: white;
    display: flex;
    position: absolute;
    bottom: 0;
    transition: all 500ms cubic-bezier(0.48, 0, 0.12, 1);
    z-index: 100;
    opacity: 0;
    top: 50%;
    left: 50%;
    transform: translateY(225%) translateX(-50%);
    height: 30px;
    line-height: 30px;
  }

  .button-57:after {
    content: "";
    position: absolute;
    bottom: -50%;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    transform-origin: bottom center;
    transition: transform 600ms cubic-bezier(0.48, 0, 0.12, 1);
    transform: skewY(9.3deg) scaleY(0);
    z-index: 50;
  }

  .button-57:hover:after {
    transform-origin: bottom center;
    transform: skewY(9.3deg) scaleY(2);
  }

  .button-57:hover span:last-child {
    transform: translateX(-50%) translateY(-100%);
    opacity: 1;
    transition: all 900ms cubic-bezier(0.48, 0, 0.12, 1);
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .placeAndWL{
    display: none;
  }
  .navBar {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .discord4 {
    display: none;
  }

  /*.............. FAQ ..............*/

  summary {
    font-size: 30px;
    font-weight: 600;
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;

    border: 2px solid #414141;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 70%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .faq__content {

    color: rgba(255, 255, 255, 0.829);
    padding-bottom: 20px;
    text-align: left;
    font-size: 25px;
  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 90px;
    color: white;
    text-shadow: 0 0 5px #ffffff;
  }

  .faqbg {
    width: 100%;
    text-align: left;
    padding: 2px;
    padding-bottom: 20px;
    margin-right: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: 100%;
    padding-top: 5%;
    padding-bottom: 10%;
  }

  .writebtn {
    background-color: #53535385;
    color: white;
    padding: 10px;
    border-radius: 10px;
    border: none;
  }


  .mintingAmount {
    font-size: 20px;
    padding: 10px;

  }

  .adH {
    font-size: 40px;
    color: white;
    text-align: center;
    font-weight: bold;
  }

  .boxWrap6 {
    margin-top: 5%;
  }

  .osPic {
    width: 30px;
    height: 30px;
    cursor: pointer;

  }

  .price {
    text-align: center;
    font-size: 32px;
    color: #ffffff;
  }

  .discord2 {
    display: none;
  }

  .discord3 {
    padding-right: 20px;
    display: none;
  }


  .uRMobile {
    display: none;
  }

  .storyPicDivMobile {
    display: none;
  }

  .allWrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
  }

  .boxWrap2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #0000003f;
    padding-top: 5%;
    padding-bottom: 5%;
    border-radius: 20%;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .boxWrap3 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 10%;
    margin-bottom: 10%;
  }

  .storyPicDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .storyH {
    font-size: 50px;
    font-weight: bold;
    text-shadow: 4px 3px 0 #383d6e62;
    color: white;
    margin-bottom: 10px;
    margin-top: 10%;
  }

  .storyCon {
    color: white;
    font-size: 22px;
    text-shadow: 0 0 10px #ffffff4b;
  }

  .storyPic {
    width: 200px;
    height: 250px;
    border: 2px solid #d423b7;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 10px #d64dbf;
  }

  .storyRight {
    width: 90%;
    text-align: center;
    margin-top: 5%;
    margin-left: auto;
    margin-right: auto;
  }

  .uR {
    width: 50%;
  }

  .intro {
    color: white;
    font-size: 60px;
    font-family: 'Russo One', sans-serif;
    margin-bottom: 5%;
    text-align: center;
  }

  .intro2 {
    color: rgba(255, 255, 255, 0.918);
    font-size: 23px;
    text-shadow: 4px 3px 0 #383d6e18;
    margin-top: -5%;
    line-height: 1.5;
    text-align: center;
  }

  .mintDiv {

    width: 60%;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 5px dashed white;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10%;
    margin-top: 5%;
  }

  .errorMessage {

    font-size: 20px;
    color: #fc534d;
    text-align: center;

  }

  .loadingContainer {}

  .loadingText {
    font-size: 25px;
    text-align: center;
    color: rgb(204, 204, 204);
  }

  .loadTextSub {
    text-align: center;
    color: rgb(185, 185, 185);
    font-size: 13px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;

  }

  .wPMobile {
    display: none;
  }

  .introductionMobile {
    display: none
  }

  .hl {
    color: #6ce34d;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 20px;
    padding-top: 20px;
    justify-content: space-evenly;
    width: max-content;
    margin-left: auto;
    margin-right: auto;
  }

  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-left: auto;
    margin-right: auto;
    width: 5%;
    justify-content: space-between;
  }

  .discord {
    padding-right: 20px;
    transition: transform .2s;
    margin-top: auto;
    margin-bottom: auto;
  }

  .discord:hover {
    transform: scale(1.1);
  }


  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }



  .note {
    color: rgb(255, 255, 255);
  }

  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;

  }

  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/

  }

  /*.website{
  margin-top: 10px;
  margin-left: 10px;
 /* z-index: -2;*/
  /*color: #ffffffa2;
}*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;

  }





  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;

    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);


  }

  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: 'Poppins', sans-serif;
    padding-top: 5px;
    margin-left: 10%;

  }

  .connect2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-top: 5px;

  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Poppins', sans-serif;
    color: white;
    padding-top: 15px;
    font-size: 16px;


  }

  .connect div:hover {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Poppins', sans-serif;
    color: rgb(184, 184, 184);
    padding-top: 15px;
    cursor: pointer;

  }


  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }




  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;

  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    height: max-content;
  }

  .nftminter2 {
    color: yellow;
    font-size: 75px;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    justify-content: center;
  }

  .nftminter {
    background-color: #18181a;
    justify-content: flex-start;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    width: 50px;
    border: none;
    cursor: pointer;
    border: 2px solid #620fb5;

  }

  .btnfos-0-2-2 {
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    width: 50px;
    border: none;
    cursor: pointer;
    border: 2px solid #620fb5;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }


  .btnfos-0-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) -5px 5px;
  }


  .btnfos-0-2-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) 5px 5px;
  }


  .btnfos-0-3 {
    font-size: 30px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 5px;
    border: 2px solid #620fb5;

    justify-content: center;
    text-align: center;
    width: 250px;
    margin-bottom: 5%;
  }

  .btnfos-0-4 {
    font-size: 30px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 5px;
    border: 2px solid rgb(255, 255, 255);
    background-color: #620fb5;
    justify-content: center;
    text-align: center;
    width: fit-content;
    margin-bottom: 5%;
    margin-top: 5%;
    color: white;
  }


  .btnfos-0-3:hover {

    color: rgb(78, 78, 78);
    cursor: pointer;

  }

  .btnfos-0-3:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(141, 141, 141, 0.288) 0px 5px;
  }

  .nftamount {
    color: rgb(255, 174, 0);
    font-size: 120px;
    text-shadow: 4px 3px 0 #383d6e17;
  }

  .walletConnect {
    display: flex;
    margin-top: 2%;
    margin-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .connectButton {

    font-size: 45px;
  }

  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;

  }

  .loadingContainer {
    text-align: center;
  }

  .successfully {
    text-align: center;
    color: rgb(124, 199, 74);
    font-size: 25px;
  }


  .headers {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding-top: 12px;
    margin-left: auto;
    margin-right: auto;

    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/

  }

  .headers2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-bottom: 12px;
    padding-top: 12px;
    width: 90%;

    margin-left: auto;
    margin-right: auto;


    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/

  }


  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-top: 2%;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .wallet2 {
    width: 230px;
    padding: 5px;
    transition: transform .3s;
    font-size: 20px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    background-color: #efb03a00;
    border: 2px solid #ac01ac;

    color: white;
  }

  .wallet2:hover {
        background-color: rgba(255, 255, 255, 0.103);

  }

  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }

  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }


  .wallet3 {

    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 40px;
    padding-left: 50px;
    padding-right: 50px;
    align-items: center;
    background: rgb(92, 1, 91);
    background: linear-gradient(354deg, rgba(92, 1, 91, 1) 1%, rgba(75, 12, 140, 1) 61%);
    border: 0;
    box-sizing: border-box;
    box-sizing: border-box;
    color: white;
    display: flex;
    transition: transform .5s;
    font-family: 'Russo One', sans-serif;

  }

  .wallet3:hover {
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
    transform: scale(1.2);
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  .in2 {
    width: 90%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }

  .totalSupply {
    text-align: center;
    font-size: 50px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    text-shadow: 4px 3px 0 #383d6e3a;
    letter-spacing: 1px;
    font-family: 'Russo One', sans-serif;
  }

  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }

    10%,
    87.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    20%,
    92.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    25%,
    95% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  .logoPic {
    border-radius: 50%;
    display: none;
    width: 100px;
    height: 100px;
    border: 2px solid #7212c0;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 10px #780594;
  }

  .logo {
    margin-top: auto;
    margin-bottom: auto;
  }

  .footer {
    background-color: rgba(0, 0, 0, 0);
    padding: 2%;
    position: static;
    margin-top: 10%;
  }

  .copyright {
    color: rgba(255, 255, 255, 0.712);
    text-align: center;
    font-size: 50px;
    text-decoration: underline;
  }

  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }

  .light2 {
    height: 200vh;
  }

  .light {
    background: radial-gradient(circle, rgba(0, 0, 0, 0.6642254313834909) 1%, rgba(0, 0, 0, 0.9471385966495973) 49%, rgba(0, 0, 0, 0.9247296330641632) 100%), url('./assets/bg.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100vh;
    height: max-content;
    overflow: hidden;
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }

    100% {
      transform: translateX(0);
    }
  }

  @keyframes Slide_Left_Right {

    0%,
    100% {
      transform: translateX(100px);
    }

    12.5%,
    75% {
      transform: translateX(0);
    }

    25% {
      transform: translateX(20px);
    }

  }

  @keyframes Opacity {

    0%,
    100% {
      opacity: 0;
    }

    25%,
    75% {
      opacity: 1;
    }


  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }

  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }

    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }

    50% {
      transform: scale(1.05, 1.05);
    }

    100% {
      transform: scale(1, 1);
    }
  }

  .beast {
    width: 400px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
    border: white 2px solid;
    border: 2px solid #7212c0;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 10px #780594;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 5%;

  }

  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }

    50% {
      transform: scale(1.05, 1.05);
    }

    100% {
      transform: scale(1, 1);
    }
  }

  /* CSS */
  .button-57 {
    position: relative;
    overflow: hidden;
    border: 1px solid #18181a;
    color: #ffffff;
    display: inline-block;
    font-size: 70px;
    line-height: 15px;
    padding: 50px 50px 50px;
    text-decoration: none;
    cursor: pointer;
    background: rgba(240, 97, 221, 0.171);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    letter-spacing: 3px;

  }

  .button-57 span:first-child {
    position: relative;
    transition: color 600ms cubic-bezier(0.48, 0, 0.12, 1);
    z-index: 10;
  }

  .button-57 span:last-child {
    color: white;
    display: flex;
    position: absolute;
    bottom: 0;
    transition: all 500ms cubic-bezier(0.48, 0, 0.12, 1);
    z-index: 100;
    opacity: 0;
    top: 50%;
    left: 50%;
    transform: translateY(225%) translateX(-50%);
    height: 30px;
    line-height: 30px;
  }

  .button-57:after {
    content: "";
    position: absolute;
    bottom: -50%;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    transform-origin: bottom center;
    transition: transform 600ms cubic-bezier(0.48, 0, 0.12, 1);
    transform: skewY(9.3deg) scaleY(0);
    z-index: 50;
  }

  .button-57:hover:after {
    transform-origin: bottom center;
    transform: skewY(9.3deg) scaleY(2);
  }

  .button-57:hover span:last-child {
    transform: translateX(-50%) translateY(-100%);
    opacity: 1;
    transition: all 900ms cubic-bezier(0.48, 0, 0.12, 1);
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .placeAndWL {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    display: none;
  }

  .discord4 {
    display: none;
  }

  /*.............. FAQ ..............*/

  summary {
    font-size: 30px;
    font-weight: 600;
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;

    border: 2px solid #414141;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 70%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .faq__content {

    color: rgba(255, 255, 255, 0.829);
    padding-bottom: 20px;
    text-align: left;
    font-size: 25px;
  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 90px;
    color: white;
    text-shadow: 0 0 5px #ffffff;
  }

  .faqbg {
    width: 100%;
    text-align: left;
    padding: 2px;
    padding-bottom: 20px;
    margin-right: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: 100%;
    padding-top: 5%;
    padding-bottom: 10%;
  }

  .writebtn {
    background-color: #53535385;
    color: white;
    padding: 10px;
    border-radius: 10px;
    border: none;
  }


  .mintingAmount {
    font-size: 20px;
    padding: 10px;

  }

  .adH {
    font-size: 40px;
    color: white;
    text-align: center;
    font-weight: bold;
  }

  .boxWrap6 {
    margin-top: 5%;
  }

  .osPic {
    width: 30px;
    height: 30px;
    cursor: pointer;

  }

  .price {
    text-align: center;
    font-size: 28px;
    color: #ffffff;
  }

  .discord2 {
    display: none;
  }

  .discord3 {
    padding-right: 20px;
    display: none;
  }


  .uRMobile {
    display: none;
  }

  .storyPicDivMobile {
    display: none;
  }

  .allWrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
  }

  .boxWrap2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #0000003f;
    padding-top: 5%;
    padding-bottom: 5%;
    border-radius: 20%;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .boxWrap3 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 10%;
    margin-bottom: 10%;
  }

  .storyPicDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .storyH {
    font-size: 50px;
    font-weight: bold;
    text-shadow: 4px 3px 0 #383d6e62;
    color: white;
    margin-bottom: 10px;
    margin-top: 10%;
  }

  .storyCon {
    color: white;
    font-size: 22px;
    text-shadow: 0 0 10px #ffffff4b;
  }

  .storyPic {
    width: 200px;
    height: 250px;
    border: 2px solid #d423b7;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 10px #d64dbf;
  }

  .storyRight {
    width: 90%;
    text-align: center;
    margin-top: 5%;
    margin-left: auto;
    margin-right: auto;
  }

  .uR {
    width: 50%;
  }

  .intro {
    color: white;
    font-size: 60px;
    font-family: 'Russo One', sans-serif;
    margin-bottom: 5%;
  }

  .intro2 {
    color: rgba(255, 255, 255, 0.918);
    font-size: 23px;
    text-shadow: 4px 3px 0 #383d6e18;
    margin-top: -5%;
    line-height: 1.5;
    text-align: justify;
  }

  .mintDiv {

    width: 45%;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 5px dashed white;
  }

  .errorMessage {

    font-size: 20px;
    color: #fc534d;
    text-align: center;

  }

  .loadingContainer {}

  .loadingText {
    font-size: 25px;
    text-align: center;
    color: rgb(204, 204, 204);
  }

  .loadTextSub {
    text-align: center;
    color: rgb(185, 185, 185);
    font-size: 13px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;

  }

  .wPMobile {
    display: none;
  }

  .introductionMobile {
    display: none
  }

  .hl {
    color: #6ce34d;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 20px;
    padding-top: 20px;
    justify-content: space-evenly;
    width: max-content;
  }

  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-left: auto;
    margin-right: auto;
    width: 5%;
    justify-content: space-between;
  }

  .discord {
    padding-right: 20px;
    transition: transform .2s;
    margin-top: auto;
    margin-bottom: auto;
  }

  .discord:hover {
    transform: scale(1.1);
  }


  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }



  .note {
    color: rgb(255, 255, 255);
  }

  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;

  }

  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/

  }

  /*.website{
  margin-top: 10px;
  margin-left: 10px;
 /* z-index: -2;*/
  /*color: #ffffffa2;
}*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;

  }





  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;

    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);


  }

  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: 'Poppins', sans-serif;
    padding-top: 5px;
    margin-left: 10%;

  }

  .connect2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-top: 5px;

  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Poppins', sans-serif;
    color: white;
    padding-top: 15px;
    font-size: 16px;


  }

  .connect div:hover {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Poppins', sans-serif;
    color: rgb(184, 184, 184);
    padding-top: 15px;
    cursor: pointer;

  }


  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }




  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;

  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    height: max-content;
  }

  .nftminter2 {
    color: yellow;
    font-size: 75px;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    justify-content: center;
  }

  .nftminter {
    background-color: #18181a;
    justify-content: flex-start;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    width: 50px;
    border: none;
    cursor: pointer;
    border: 2px solid #620fb5;

  }

  .btnfos-0-2-2 {
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    width: 50px;
    border: none;
    cursor: pointer;
    border: 2px solid #620fb5;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }


  .btnfos-0-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) -5px 5px;
  }


  .btnfos-0-2-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) 5px 5px;
  }


  .btnfos-0-3 {
    font-size: 30px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 5px;
    border: 2px solid #620fb5;

    justify-content: center;
    text-align: center;
    width: 250px;
    margin-bottom: 5%;
  }

  .btnfos-0-4 {
    font-size: 30px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 5px;
    border: 2px solid rgb(255, 255, 255);
    background-color: #620fb5;
    justify-content: center;
    text-align: center;
    width: fit-content;
    margin-bottom: 5%;
    margin-top: 5%;
    color: white;
  }


  .btnfos-0-3:hover {

    color: rgb(78, 78, 78);
    cursor: pointer;

  }

  .btnfos-0-3:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(141, 141, 141, 0.288) 0px 5px;
  }

  .nftamount {
    color: rgb(255, 174, 0);
    font-size: 120px;
    text-shadow: 4px 3px 0 #383d6e17;
  }

  .walletConnect {
    display: flex;
    margin-top: 2%;
  }

  .connectButton {

    font-size: 45px;
  }

  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;

  }

  .loadingContainer {
    text-align: center;
  }

  .successfully {
    text-align: center;
    color: rgb(124, 199, 74);
    font-size: 25px;
  }


  .headers {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding-top: 12px;
    margin-left: auto;
    margin-right: auto;

    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/

  }

  .headers2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-bottom: 12px;
    padding-top: 12px;
    width: 90%;

    margin-left: auto;
    margin-right: auto;


    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/

  }


  .introduction {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: 2%;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .wallet2 {
    width: 230px;
    padding: 5px;
    transition: transform .3s;
    font-size: 20px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    background-color: #efb03a00;
border: 2px solid #ac01ac;

    color: white;
  }

  .wallet2:hover {
        background-color: rgba(255, 255, 255, 0.103);

  }

  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }

  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }


  .wallet3 {

    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 40px;
    padding-left: 50px;
    padding-right: 50px;
    align-items: center;
    background: rgb(92, 1, 91);
    background: linear-gradient(354deg, rgba(92, 1, 91, 1) 1%, rgba(75, 12, 140, 1) 61%);
    border: 0;
    box-sizing: border-box;
    box-sizing: border-box;
    color: white;
    display: flex;
    transition: transform .5s;
    font-family: 'Russo One', sans-serif;

  }

  .wallet3:hover {
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
    transform: scale(1.2);
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  .in2 {
    width: 50%;
    text-align: left;
    margin-top: auto;
    margin-bottom: auto;
  }

  .totalSupply {
    text-align: center;
    font-size: 50px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    text-shadow: 4px 3px 0 #383d6e3a;
    letter-spacing: 1px;
    font-family: 'Russo One', sans-serif;
  }

  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }

    10%,
    87.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    20%,
    92.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    25%,
    95% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  .logoPic {
    border-radius: 50%;
    width: 100px;
    height: 100px;
    border: 2px solid #7212c0;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 10px #780594;
  }

  .logo {
    margin-top: auto;
    margin-bottom: auto;
  }

  .footer {
    background-color: rgba(0, 0, 0, 0);
    padding: 2%;
    position: static;
    margin-top: 10%;
  }

  .copyright {
    color: rgba(255, 255, 255, 0.712);
    text-align: center;
    font-size: 50px;
    text-decoration: underline;
  }

  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }

  .light2 {
    height: 200vh;
  }

  .light {
    background: radial-gradient(circle, rgba(0, 0, 0, 0.6642254313834909) 1%, rgba(0, 0, 0, 0.9471385966495973) 49%, rgba(0, 0, 0, 0.9247296330641632) 100%), url('./assets/bg.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100vh;
    height: max-content;
    overflow: hidden;
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }

    100% {
      transform: translateX(0);
    }
  }

  @keyframes Slide_Left_Right {

    0%,
    100% {
      transform: translateX(100px);
    }

    12.5%,
    75% {
      transform: translateX(0);
    }

    25% {
      transform: translateX(20px);
    }

  }

  @keyframes Opacity {

    0%,
    100% {
      opacity: 0;
    }

    25%,
    75% {
      opacity: 1;
    }


  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }

  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }

    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }

    50% {
      transform: scale(1.05, 1.05);
    }

    100% {
      transform: scale(1, 1);
    }
  }

  .beast {
    width: 400px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
    border: white 2px solid;
    border: 2px solid #7212c0;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 10px #780594;
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }

    50% {
      transform: scale(1.05, 1.05);
    }

    100% {
      transform: scale(1, 1);
    }
  }

  /* CSS */
  .button-57 {
    position: relative;
    overflow: hidden;
    border: 1px solid #18181a;
    color: #ffffff;
    display: inline-block;
    font-size: 70px;
    line-height: 15px;
    padding: 50px 50px 50px;
    text-decoration: none;
    cursor: pointer;
    background: rgba(240, 97, 221, 0.171);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    letter-spacing: 3px;

  }

  .button-57 span:first-child {
    position: relative;
    transition: color 600ms cubic-bezier(0.48, 0, 0.12, 1);
    z-index: 10;
  }

  .button-57 span:last-child {
    color: white;
    display: flex;
    position: absolute;
    bottom: 0;
    transition: all 500ms cubic-bezier(0.48, 0, 0.12, 1);
    z-index: 100;
    opacity: 0;
    top: 50%;
    left: 50%;
    transform: translateY(225%) translateX(-50%);
    height: 30px;
    line-height: 30px;
  }

  .button-57:after {
    content: "";
    position: absolute;
    bottom: -50%;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    transform-origin: bottom center;
    transition: transform 600ms cubic-bezier(0.48, 0, 0.12, 1);
    transform: skewY(9.3deg) scaleY(0);
    z-index: 50;
  }

  .button-57:hover:after {
    transform-origin: bottom center;
    transform: skewY(9.3deg) scaleY(2);
  }

  .button-57:hover span:last-child {
    transform: translateX(-50%) translateY(-100%);
    opacity: 1;
    transition: all 900ms cubic-bezier(0.48, 0, 0.12, 1);
  }
}

@media screen and (min-width: 1200px) and (max-width: 1919px) {

  .webIcon {
    background-color: white;
  }

  .discord4 {
    display: none;
  }

  /*.............. FAQ ..............*/

  summary {
    font-size: 30px;
    font-weight: 600;
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;

    border: 2px solid #414141;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 70%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .faq__content {

    color: rgba(255, 255, 255, 0.829);
    padding-bottom: 20px;
    text-align: left;
    font-size: 25px;
  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 90px;
    color: white;
    text-shadow: 0 0 5px #ffffff;
  }

  .faqbg {
    width: 100%;
    text-align: left;
    padding: 2px;
    padding-bottom: 20px;
    margin-right: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: 100%;
    padding-top: 5%;
    padding-bottom: 10%;
  }

  .writebtn {
    background-color: #53535385;
    color: white;
    padding: 10px;
    border-radius: 10px;
    border: none;
  }

  .mintingAmount {
    font-size: 20px;
    padding: 10px;

  }

  .adH {
    font-size: 40px;
    color: white;
    text-align: center;
    font-weight: bold;
  }

  .boxWrap6 {
    margin-top: 5%;
  }

  .osPic {
    width: 30px;
    height: 30px;
    cursor: pointer;

  }

  .price {
    text-align: center;
    font-size: 30px;
    color: #ffffff;
  }

  .discord2 {
    display: none;
  }

  .discord3 {
    padding-right: 20px;
    display: none;
  }


  .uRMobile {
    display: none;
  }

  .storyPicDivMobile {
    display: none;
  }

  .allWrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
  }

  .boxWrap2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #0000003f;
    padding-top: 5%;
    padding-bottom: 5%;
    border-radius: 20%;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .boxWrap3 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 10%;
    margin-bottom: 10%;
  }

  .storyPicDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .storyH {
    font-size: 50px;
    font-weight: bold;
    text-shadow: 4px 3px 0 #383d6e62;
    color: white;
    margin-bottom: 10px;
    margin-top: 10%;
  }

  .storyCon {
    color: white;
    font-size: 22px;
    text-shadow: 0 0 10px #ffffff4b;
  }

  .storyPic {
    width: 200px;
    height: 250px;
    border: 2px solid #d423b7;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 10px #d64dbf;
  }

  .storyRight {
    width: 90%;
    text-align: center;
    margin-top: 5%;
    margin-left: auto;
    margin-right: auto;
  }

  .uR {
    width: 50%;
  }

  .intro {
    color: white;
    font-size: 60px;
    font-family: 'Russo One', sans-serif;
    margin-bottom: 5%;
  }

  .intro2 {
    color: rgba(255, 255, 255, 0.918);
    font-size: 20px;
    text-shadow: 4px 3px 0 #383d6e18;
    margin-top: -5%;
    text-align: justify;
    line-height: 1.5;
  }

  .mintDiv {

    width: 40%;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 5px dashed white;
  }

  .errorMessage {

    font-size: 20px;
    color: #fc534d;
    text-align: center;

  }

  .loadingContainer {}

  .loadingText {
    font-size: 25px;
    text-align: center;
    color: rgb(204, 204, 204);
  }

  .loadTextSub {
    text-align: center;
    color: rgb(185, 185, 185);
    font-size: 13px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;

  }

  .wPMobile {
    display: none;
  }

  .introductionMobile {
    display: none
  }

  .hl {
    color: #6ce34d;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 20px;
    padding-top: 20px;
    justify-content: space-evenly;
    width: max-content;
  }

  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-left: auto;
    margin-right: auto;
    width: 5%;
    justify-content: space-between;
  }

  .discord {
    padding-right: 20px;
    transition: transform .2s;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .discord:hover {
    transform: scale(1.1);
  }


  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }



  .note {
    color: rgb(255, 255, 255);
  }

  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;

  }

  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/

  }

  /*.website{
  margin-top: 10px;
  margin-left: 10px;
 /* z-index: -2;*/
  /*color: #ffffffa2;
}*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;

  }





  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;

    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);


  }

  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: 'Poppins', sans-serif;
    padding-top: 5px;
    margin-left: 10%;

  }

  .connect2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-top: 5px;

  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Poppins', sans-serif;
    color: white;
    padding-top: 15px;
    font-size: 16px;


  }

  .connect div:hover {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Poppins', sans-serif;
    color: rgb(184, 184, 184);
    padding-top: 15px;
    cursor: pointer;

  }


  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }




  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;

  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    height: max-content;
  }

  .nftminter2 {
    color: yellow;
    font-size: 75px;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    justify-content: center;
  }

  .nftminter {
    background-color: #18181a;
    justify-content: flex-start;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    width: 50px;
    border: none;
    cursor: pointer;
    border: 2px solid #620fb5;

  }

  .btnfos-0-2-2 {
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    width: 50px;
    border: none;
    cursor: pointer;
    border: 2px solid #620fb5;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }


  .btnfos-0-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) -5px 5px;
  }


  .btnfos-0-2-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) 5px 5px;
  }


  .btnfos-0-3 {
    font-size: 30px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 5px;
    border: 2px solid #620fb5;

    justify-content: center;
    text-align: center;
    width: 250px;
    margin-bottom: 5%;
  }

  .btnfos-0-4 {
    font-size: 30px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 5px;
    border: 2px solid rgb(255, 255, 255);
    background-color: #620fb5;
    justify-content: center;
    text-align: center;
    margin-bottom: 5%;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    width: fit-content;

  }

  .placeAndWL {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    display: none;
  }

  .btnfos-0-3:hover {

    color: rgb(78, 78, 78);
    cursor: pointer;

  }

  .btnfos-0-3:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(141, 141, 141, 0.288) 0px 5px;
  }

  .nftamount {
    color: rgb(255, 174, 0);
    font-size: 120px;
    text-shadow: 4px 3px 0 #383d6e17;
  }

  .walletConnect {
    display: flex;
    margin-top: 2%;
  }

  .connectButton {

    font-size: 45px;
  }

  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;

  }

  .loadingContainer {
    text-align: center;
  }

  .successfully {
    text-align: center;
    color: rgb(124, 199, 74);
    font-size: 25px;
  }


  .headers {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding-top: 12px;
    margin-left: auto;
    margin-right: auto;

    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/

  }

  .headers2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-bottom: 12px;
    padding-top: 12px;
    width: 90%;

    margin-left: auto;
    margin-right: auto;


    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/

  }


  .introduction {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: 2%;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .wallet2 {
    width: 230px;
    padding: 5px;
    transition: transform .3s;
    font-size: 20px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    background-color: #efb03a00;
border: 2px solid #ac01ac;

    color: white;
  }

  .wallet2:hover {
        background-color: rgba(255, 255, 255, 0.103);

  }

  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }

  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }


  .wallet3 {

    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 40px;
    padding-left: 50px;
    padding-right: 50px;
    align-items: center;
    background: rgb(92, 1, 91);
    background: linear-gradient(354deg, rgba(92, 1, 91, 1) 1%, rgba(75, 12, 140, 1) 61%);
    border: 0;
    box-shadow: rgba(252, 224, 65, 0.2) 0 15px 30px -5px;
    box-sizing: border-box;
    box-sizing: border-box;
    color: white;
    display: flex;
    transition: transform .5s;
    font-family: 'Russo One', sans-serif;
  }

  .wallet3:hover {
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
    transform: scale(1.2);
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  .in2 {
    width: 50%;
    text-align: left;
    margin-top: auto;
    margin-bottom: auto;
  }

  .totalSupply {
    text-align: center;
    font-size: 50px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    text-shadow: 4px 3px 0 #383d6e3a;
    letter-spacing: 1px;
    font-family: 'Russo One', sans-serif;
  }

  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }

    10%,
    87.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    20%,
    92.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    25%,
    95% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  .logoPic {
    border-radius: 50%;
    width: 100px;
    height: 100px;
    border: 2px solid #7212c0;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 10px #780594;
  }

  .logo {
    margin-top: auto;
    margin-bottom: auto;
  }

  .footer {
    background-color: rgba(0, 0, 0, 0);
    padding: 2%;
    position: static;
    margin-top: 10%;
  }

  .copyright {
    color: rgba(255, 255, 255, 0.712);
    text-align: center;
    font-size: 50px;
    text-decoration: underline;
  }

  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }

  .light2 {
    height: 200vh;
  }

  .light {
    background: radial-gradient(circle, rgba(0, 0, 0, 0.6642254313834909) 1%, rgba(0, 0, 0, 0.9471385966495973) 49%, rgba(0, 0, 0, 0.9247296330641632) 100%), url('./assets/bg.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100vh;
    height: max-content;
    overflow: hidden;
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }

    100% {
      transform: translateX(0);
    }
  }

  @keyframes Slide_Left_Right {

    0%,
    100% {
      transform: translateX(100px);
    }

    12.5%,
    75% {
      transform: translateX(0);
    }

    25% {
      transform: translateX(20px);
    }

  }

  @keyframes Opacity {

    0%,
    100% {
      opacity: 0;
    }

    25%,
    75% {
      opacity: 1;
    }


  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }

  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }

    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }

    50% {
      transform: scale(1.05, 1.05);
    }

    100% {
      transform: scale(1, 1);
    }
  }

  .beast {
    width: 420px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
    border: white 2px solid;
    border: 2px solid #7212c0;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 10px #780594;
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }

    50% {
      transform: scale(1.05, 1.05);
    }

    100% {
      transform: scale(1, 1);
    }
  }

  /* CSS */
  .button-57 {
    position: relative;
    overflow: hidden;
    border: 1px solid #18181a;
    color: #ffffff;
    display: inline-block;
    font-size: 70px;
    line-height: 15px;
    padding: 50px 50px 50px;
    text-decoration: none;
    cursor: pointer;
    background: rgba(240, 97, 221, 0.171);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    letter-spacing: 3px;

  }

  .button-57 span:first-child {
    position: relative;
    transition: color 600ms cubic-bezier(0.48, 0, 0.12, 1);
    z-index: 10;
  }

  .button-57 span:last-child {
    color: white;
    display: flex;
    position: absolute;
    bottom: 0;
    transition: all 500ms cubic-bezier(0.48, 0, 0.12, 1);
    z-index: 100;
    opacity: 0;
    top: 50%;
    left: 50%;
    transform: translateY(225%) translateX(-50%);
    height: 30px;
    line-height: 30px;
  }

  .button-57:after {
    content: "";
    position: absolute;
    bottom: -50%;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    transform-origin: bottom center;
    transition: transform 600ms cubic-bezier(0.48, 0, 0.12, 1);
    transform: skewY(9.3deg) scaleY(0);
    z-index: 50;
  }

  .button-57:hover:after {
    transform-origin: bottom center;
    transform: skewY(9.3deg) scaleY(2);
  }

  .button-57:hover span:last-child {
    transform: translateX(-50%) translateY(-100%);
    opacity: 1;
    transition: all 900ms cubic-bezier(0.48, 0, 0.12, 1);
  }
}

@media screen and (min-width: 1920px) {

  .navBar img {
    width: 170%;
  }

  .placeAndWL {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    display: none;
  }

  .discord4 {
    display: none;
  }

  /*.............. FAQ ..............*/

  summary {
    font-size: 30px;
    font-weight: 600;
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;

    border: 2px solid #414141;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 70%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .faq__content {

    color: rgba(255, 255, 255, 0.829);
    padding-bottom: 20px;
    text-align: left;
    font-size: 25px;
  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 90px;
    color: white;
    text-shadow: 0 0 5px #ffffff;
  }

  .faqbg {
    width: 100%;
    text-align: left;
    padding: 2px;
    padding-bottom: 20px;
    margin-right: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: 100%;
    padding-top: 5%;
    padding-bottom: 10%;
  }

  .writebtn {
    background-color: #53535385;
    color: white;
    padding: 10px;
    border-radius: 10px;
    border: none;
  }


  .mintingAmount {
    font-size: 20px;
    padding: 10px;

  }

  .adH {
    font-size: 40px;
    color: white;
    text-align: center;
    font-weight: bold;
  }

  .boxWrap6 {
    margin-top: 5%;
  }

  .osPic {
    width: 50px;
    height: 50px;
    cursor: pointer;

  }

  .price {
    text-align: center;
    font-size: 50px;
    color: #ffffff;
  }

  .discord {
    display: none;
  }

  .discord3 {
    padding-right: 20px;
    display: none;
  }


  .uRMobile {
    display: none;
  }

  .storyPicDivMobile {
    display: none;
  }

  .allWrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
  }

  .boxWrap2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #0000003f;
    padding-top: 5%;
    padding-bottom: 5%;
    border-radius: 20%;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .boxWrap3 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 10%;
    margin-bottom: 10%;
  }

  .storyPicDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .storyH {
    font-size: 50px;
    font-weight: bold;
    text-shadow: 4px 3px 0 #383d6e62;
    color: white;
    margin-bottom: 10px;
    margin-top: 10%;
  }

  .storyCon {
    color: white;
    font-size: 22px;
    text-shadow: 0 0 10px #ffffff4b;
  }

  .storyPic {
    width: 200px;
    height: 250px;
    border: 2px solid #d423b7;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 10px #d64dbf;
  }

  .storyRight {
    width: 90%;
    text-align: center;
    margin-top: 5%;
    margin-left: auto;
    margin-right: auto;
  }

  .uR {
    width: 50%;
  }

  .intro {
    color: white;
    font-size: 70px;
    font-family: 'Russo One', sans-serif;
    margin-bottom: 5%;
  }

  .intro2 {
    color: rgba(255, 255, 255, 0.918);
    font-size: 35px;
    text-shadow: 4px 3px 0 #383d6e18;
    margin-top: -5%;
    text-align: justify;
    line-height: 1.5;
  }

  .mintDiv {

    width: 40%;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 5px dashed white;
  }

  .errorMessage {

    font-size: 20px;
    color: #fc534d;
    text-align: center;

  }

  .loadingContainer {}

  .loadingText {
    font-size: 27px;
    text-align: center;
    color: rgb(204, 204, 204);
  }

  .loadTextSub {
    text-align: center;
    color: rgb(185, 185, 185);
    font-size: 13px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;

  }

  .wPMobile {
    display: none;
  }

  .introductionMobile {
    display: none
  }

  .hl {
    color: #6ce34d;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 20px;
    padding-top: 20px;
    justify-content: space-evenly;
    width: max-content;
    margin-top: auto;
    margin-bottom: auto;
  }

  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-left: auto;
    margin-right: auto;
    width: 5%;
    justify-content: space-between;
  }

  .discord2 {
    padding-right: 20px;
    transition: transform .2s;
    margin-top: auto;
    margin-bottom: auto;
  }

  .discord2:hover {
    transform: scale(1.1);
  }


  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }



  .note {
    color: rgb(255, 255, 255);
  }

  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;

  }

  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/

  }

  /*.website{
  margin-top: 10px;
  margin-left: 10px;
 /* z-index: -2;*/
  /*color: #ffffffa2;
}*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;

  }





  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;

    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);


  }

  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: 'Poppins', sans-serif;
    padding-top: 5px;
    margin-left: 10%;

  }

  .connect2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-top: 5px;

  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Poppins', sans-serif;
    color: white;
    padding-top: 15px;
    font-size: 16px;


  }

  .connect div:hover {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Poppins', sans-serif;
    color: rgb(184, 184, 184);
    padding-top: 15px;
    cursor: pointer;

  }


  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }




  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;

  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    height: max-content;
  }

  .nftminter2 {
    color: yellow;
    font-size: 85px;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    justify-content: center;
  }

  .nftminter {
    background-color: #18181a;
    justify-content: flex-start;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    width: 75px;
    border: none;
    cursor: pointer;
    border: 2px solid #620fb5;

  }

  .btnfos-0-2-2 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    width: 75px;
    border: none;
    cursor: pointer;
    border: 2px solid #620fb5;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }


  .btnfos-0-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) -5px 5px;
  }


  .btnfos-0-2-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) 5px 5px;
  }


  .btnfos-0-3 {
    font-size: 50px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 5px;
    border: 2px solid #620fb5;

    justify-content: center;
    text-align: center;
    width: 330px;
    margin-bottom: 5%;
  }

  .btnfos-0-4 {
    font-size: 50px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 5px;
    border: 2px solid rgb(255, 255, 255);
    background-color: #620fb5;
    justify-content: center;
    text-align: center;
    width: 330px;
    margin-bottom: 5%;
    color: white;
    margin-top: 5%;
    width: fit-content;

  }


  .btnfos-0-3:hover {

    color: rgb(78, 78, 78);
    cursor: pointer;

  }

  .btnfos-0-3:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(141, 141, 141, 0.288) 0px 5px;
  }

  .nftamount {
    color: rgb(255, 174, 0);
    font-size: 120px;
    text-shadow: 4px 3px 0 #383d6e17;
  }

  .walletConnect {
    display: flex;
    margin-top: 2%;
  }

  .connectButton {

    font-size: 45px;
  }

  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;

  }

  .loadingContainer {
    text-align: center;
  }

  .successfully {
    text-align: center;
    color: rgb(124, 199, 74);
    font-size: 27px;
  }


  .headers {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding-top: 12px;
    margin-left: auto;
    margin-right: auto;

    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/

  }

  .headers2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-bottom: 12px;
    padding-top: 12px;
    width: 90%;

    margin-left: auto;
    margin-right: auto;


    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/

  }


  .introduction {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: 2%;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .wallet2 {
    width: 350px;
    padding: 5px;
    transition: transform .3s;
    font-size: 35px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    background-color: #efb03a00;
border: 2px solid #ac01ac;

    color: white;
  }

  .wallet2:hover {
        background-color: rgba(255, 255, 255, 0.103);

  }

  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }

  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }


  .wallet3 {

    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 70px;
    padding-left: 50px;
    padding-right: 50px;
    align-items: center;
    background: rgb(92, 1, 91);
    background: linear-gradient(354deg, rgba(92, 1, 91, 1) 1%, rgba(75, 12, 140, 1) 61%);
    border: 0;
    box-shadow: rgba(252, 224, 65, 0.2) 0 15px 30px -5px;
    box-sizing: border-box;
    box-sizing: border-box;
    color: white;
    display: flex;
    transition: transform .5s;
    font-family: 'Russo One', sans-serif;

  }

  .wallet3:hover {
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
    transform: scale(1.2);
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  .in2 {
    width: 50%;
    text-align: left;
    margin-top: auto;
    margin-bottom: auto;
  }

  .totalSupply {
    text-align: center;
    font-size: 100px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    text-shadow: 4px 3px 0 #383d6e3a;
    letter-spacing: 1px;
    font-family: 'Russo One', sans-serif;
  }

  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }

    10%,
    87.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    20%,
    92.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    25%,
    95% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  .logoPic {
    border-radius: 50%;
    width: 150px;
    height: 150px;
    border: 2px solid #7212c0;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 10px #780594;
  }

  .logo {
    margin-top: auto;
    margin-bottom: auto;
  }

  .footer {
    background-color: rgba(0, 0, 0, 0);
    padding: 2%;
    position: static;
    margin-top: 10%;
  }

  .copyright {
    color: rgba(255, 255, 255, 0.712);
    text-align: center;
    font-size: 50px;
    text-decoration: underline;
  }

  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }

  .light2 {
    height: 200vh;
  }

  .light {
    background: radial-gradient(circle, rgba(0, 0, 0, 0.6642254313834909) 1%, rgba(0, 0, 0, 0.9471385966495973) 49%, rgba(0, 0, 0, 0.9247296330641632) 100%), url('./assets/bg.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100vh;
    height: max-content;
    overflow: hidden;
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }

    100% {
      transform: translateX(0);
    }
  }

  @keyframes Slide_Left_Right {

    0%,
    100% {
      transform: translateX(100px);
    }

    12.5%,
    75% {
      transform: translateX(0);
    }

    25% {
      transform: translateX(20px);
    }

  }

  @keyframes Opacity {

    0%,
    100% {
      opacity: 0;
    }

    25%,
    75% {
      opacity: 1;
    }


  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }

  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }

    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }

    50% {
      transform: scale(1.05, 1.05);
    }

    100% {
      transform: scale(1, 1);
    }
  }

  .beast {
    width: 700px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
    border: white 2px solid;
    border: 2px solid #7212c0;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 10px #780594;

  }

  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }

    50% {
      transform: scale(1.05, 1.05);
    }

    100% {
      transform: scale(1, 1);
    }
  }

  /* CSS */
  .button-57 {
    position: relative;
    overflow: hidden;
    border: 1px solid #18181a;
    color: #ffffff;
    display: inline-block;
    font-size: 70px;
    line-height: 15px;
    padding: 50px 50px 50px;
    text-decoration: none;
    cursor: pointer;
    background: rgba(240, 97, 221, 0.171);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    letter-spacing: 3px;

  }

  .button-57 span:first-child {
    position: relative;
    transition: color 600ms cubic-bezier(0.48, 0, 0.12, 1);
    z-index: 10;
  }

  .button-57 span:last-child {
    color: white;
    display: flex;
    position: absolute;
    bottom: 0;
    transition: all 500ms cubic-bezier(0.48, 0, 0.12, 1);
    z-index: 100;
    opacity: 0;
    top: 50%;
    left: 50%;
    transform: translateY(225%) translateX(-50%);
    height: 30px;
    line-height: 30px;
  }

  .button-57:after {
    content: "";
    position: absolute;
    bottom: -50%;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    transform-origin: bottom center;
    transition: transform 600ms cubic-bezier(0.48, 0, 0.12, 1);
    transform: skewY(9.3deg) scaleY(0);
    z-index: 50;
  }

  .button-57:hover:after {
    transform-origin: bottom center;
    transform: skewY(9.3deg) scaleY(2);
  }

  .button-57:hover span:last-child {
    transform: translateX(-50%) translateY(-100%);
    opacity: 1;
    transition: all 900ms cubic-bezier(0.48, 0, 0.12, 1);
  }
}


@media screen and (min-width: 1921px) and (max-width: 3839px) {
  .placeAndWL {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    display: none;
  }

  .discord4 {
    display: none;
  }

  /*.............. FAQ ..............*/

  summary {
    font-size: 30px;
    font-weight: 600;
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;

    border: 2px solid #414141;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 70%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .faq__content {

    color: rgba(255, 255, 255, 0.829);
    padding-bottom: 20px;
    text-align: left;
    font-size: 25px;
  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 90px;
    color: white;
    text-shadow: 0 0 5px #ffffff;
  }

  .faqbg {
    width: 100%;
    text-align: left;
    padding: 2px;
    padding-bottom: 20px;
    margin-right: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: 100%;
    padding-top: 5%;
    padding-bottom: 10%;
  }

  .writebtn {
    background-color: #53535385;
    color: white;
    padding: 10px;
    border-radius: 10px;
    border: none;
  }


  .mintingAmount {
    font-size: 20px;
    padding: 10px;

  }

  .adH {
    font-size: 40px;
    color: white;
    text-align: center;
    font-weight: bold;
  }

  .boxWrap6 {
    margin-top: 5%;
  }

  .osPic {
    width: 50px;
    height: 50px;
    cursor: pointer;

  }

  .price {
    text-align: center;
    font-size: 50px;
    color: #ffffff;
  }

  .discord {
    display: none;
  }

  .discord3 {
    padding-right: 20px;
    display: none;
  }


  .uRMobile {
    display: none;
  }

  .storyPicDivMobile {
    display: none;
  }

  .allWrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
  }

  .boxWrap2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #0000003f;
    padding-top: 5%;
    padding-bottom: 5%;
    border-radius: 20%;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .boxWrap3 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 10%;
    margin-bottom: 10%;
  }

  .storyPicDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .storyH {
    font-size: 50px;
    font-weight: bold;
    text-shadow: 4px 3px 0 #383d6e62;
    color: white;
    margin-bottom: 10px;
    margin-top: 10%;
  }

  .storyCon {
    color: white;
    font-size: 22px;
    text-shadow: 0 0 10px #ffffff4b;
  }

  .storyPic {
    width: 200px;
    height: 250px;
    border: 2px solid #d423b7;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 10px #d64dbf;
  }

  .storyRight {
    width: 90%;
    text-align: center;
    margin-top: 5%;
    margin-left: auto;
    margin-right: auto;
  }

  .uR {
    width: 50%;
  }

  .intro {
    color: white;
    font-size: 130px;
    font-family: 'Russo One', sans-serif;
    margin-bottom: 5%;
  }

  .intro2 {
    color: rgba(255, 255, 255, 0.918);
    font-size: 45px;
    text-shadow: 4px 3px 0 #383d6e18;
    margin-top: -5%;
    text-align: justify;
    line-height: 1.5;
  }

  .mintDiv {

    width: 40%;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 5px dashed white;
  }

  .errorMessage {

    font-size: 20px;
    color: #fc534d;
    text-align: center;

  }

  .loadingContainer {}

  .loadingText {
    font-size: 30px;
    text-align: center;
    color: rgb(204, 204, 204);
  }

  .loadTextSub {
    text-align: center;
    color: rgb(185, 185, 185);
    font-size: 13px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;

  }

  .wPMobile {
    display: none;
  }

  .introductionMobile {
    display: none
  }

  .hl {
    color: #6ce34d;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 20px;
    padding-top: 20px;
    justify-content: space-evenly;
    width: max-content;
    margin-top: auto;
    margin-bottom: auto;
  }

  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-left: auto;
    margin-right: auto;
    width: 5%;
    justify-content: space-between;
  }

  .discord2 {
    padding-right: 20px;
    transition: transform .2s;
    margin-top: auto;
    margin-bottom: auto;
  }

  .discord2:hover {
    transform: scale(1.1);
  }


  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }



  .note {
    color: rgb(255, 255, 255);
  }

  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;

  }

  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/

  }

  /*.website{
  margin-top: 10px;
  margin-left: 10px;
 /* z-index: -2;*/
  /*color: #ffffffa2;
}*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;

  }





  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;

    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);


  }

  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: 'Poppins', sans-serif;
    padding-top: 5px;
    margin-left: 10%;

  }

  .connect2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-top: 5px;

  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Poppins', sans-serif;
    color: white;
    padding-top: 15px;
    font-size: 16px;


  }

  .connect div:hover {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Poppins', sans-serif;
    color: rgb(184, 184, 184);
    padding-top: 15px;
    cursor: pointer;

  }


  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }




  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;

  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    height: max-content;
    margin-top: 5%;
  }

  .nftminter2 {
    color: yellow;
    font-size: 120px;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    justify-content: center;
  }

  .nftminter {
    background-color: #18181a;
    justify-content: flex-start;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    width: 75px;
    border: none;
    cursor: pointer;
    border: 2px solid #620fb5;

  }

  .btnfos-0-2-2 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    width: 75px;
    border: none;
    cursor: pointer;
    border: 2px solid #620fb5;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }


  .btnfos-0-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) -5px 5px;
  }


  .btnfos-0-2-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) 5px 5px;
  }


  .btnfos-0-3 {
    font-size: 50px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 5px;
    border: 2px solid #620fb5;

    justify-content: center;
    text-align: center;
    width: 330px;
    margin-bottom: 5%;
    margin-top: 10%;
  }

  .btnfos-0-4 {
    font-size: 50px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 5px;
    border: 2px solid rgb(255, 255, 255);
    background-color: #620fb5;
    justify-content: center;
    text-align: center;
    width: 330px;
    margin-bottom: 5%;
    color: white;
    margin-top: 5%;
    width: fit-content;

  }


  .btnfos-0-3:hover {

    color: rgb(78, 78, 78);
    cursor: pointer;

  }

  .btnfos-0-3:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(141, 141, 141, 0.288) 0px 5px;
  }

  .nftamount {
    color: rgb(255, 174, 0);
    font-size: 120px;
    text-shadow: 4px 3px 0 #383d6e17;
  }

  .walletConnect {
    display: flex;
    margin-top: 2%;
  }

  .connectButton {

    font-size: 45px;
  }

  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;

  }

  .loadingContainer {
    text-align: center;
  }

  .successfully {
    text-align: center;
    color: rgb(124, 199, 74);
    font-size: 30px;
  }


  .headers {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding-top: 12px;
    margin-left: auto;
    margin-right: auto;

    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/

  }

  .headers2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-bottom: 12px;
    padding-top: 12px;
    width: 90%;

    margin-left: auto;
    margin-right: auto;


    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/

  }


  .introduction {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: 2%;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .wallet2 {
    width: 350px;
    padding: 5px;
    transition: transform .3s;
    font-size: 35px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    background-color: #efb03a00;
border: 2px solid #ac01ac;

    color: white;
  }

  .wallet2:hover {
        background-color: rgba(255, 255, 255, 0.103);

  }

  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }

  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }


  .wallet3 {

    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 80px;
    padding-left: 50px;
    padding-right: 50px;
    align-items: center;
    background: rgb(92, 1, 91);
    background: linear-gradient(354deg, rgba(92, 1, 91, 1) 1%, rgba(75, 12, 140, 1) 61%);
    border: 0;
    box-sizing: border-box;
    box-sizing: border-box;
    color: white;
    display: flex;
    transition: transform .5s;
    font-family: 'Russo One', sans-serif;

  }

  .wallet3:hover {
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
    transform: scale(1.2);
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  .in2 {
    width: 50%;
    text-align: left;
    margin-top: auto;
    margin-bottom: auto;
  }

  .totalSupply {
    text-align: center;
    font-size: 100px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    text-shadow: 4px 3px 0 #383d6e3a;
    letter-spacing: 1px;
    font-family: 'Russo One', sans-serif;
  }

  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }

    10%,
    87.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    20%,
    92.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    25%,
    95% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  .logoPic {
    border-radius: 50%;
    width: 150px;
    height: 150px;
    border: 2px solid #7212c0;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 10px #780594;
  }

  .logo {
    margin-top: auto;
    margin-bottom: auto;
  }

  .footer {
    background-color: rgba(0, 0, 0, 0);
    padding: 2%;
    position: static;
    margin-top: 10%;
  }

  .copyright {
    color: rgba(255, 255, 255, 0.712);
    text-align: center;
    font-size: 50px;
    text-decoration: underline;
  }

  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }

  .light2 {
    height: 200vh;
  }

  .light {
    background: radial-gradient(circle, rgba(0, 0, 0, 0.6642254313834909) 1%, rgba(0, 0, 0, 0.9471385966495973) 49%, rgba(0, 0, 0, 0.9247296330641632) 100%), url('./assets/bg.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100vh;
    height: max-content;
    overflow: hidden;
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }

    100% {
      transform: translateX(0);
    }
  }

  @keyframes Slide_Left_Right {

    0%,
    100% {
      transform: translateX(100px);
    }

    12.5%,
    75% {
      transform: translateX(0);
    }

    25% {
      transform: translateX(20px);
    }

  }

  @keyframes Opacity {

    0%,
    100% {
      opacity: 0;
    }

    25%,
    75% {
      opacity: 1;
    }


  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }

  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }

    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }

    50% {
      transform: scale(1.05, 1.05);
    }

    100% {
      transform: scale(1, 1);
    }
  }

  .beast {
    width: 800px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
    border: white 2px solid;
    border: 2px solid #7212c0;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 10px #780594;

  }

  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }

    50% {
      transform: scale(1.05, 1.05);
    }

    100% {
      transform: scale(1, 1);
    }
  }

  /* CSS */
  .button-57 {
    position: relative;
    overflow: hidden;
    border: 1px solid #18181a;
    color: #ffffff;
    display: inline-block;
    font-size: 70px;
    line-height: 15px;
    padding: 50px 50px 50px;
    text-decoration: none;
    cursor: pointer;
    background: rgba(240, 97, 221, 0.171);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    letter-spacing: 3px;

  }

  .button-57 span:first-child {
    position: relative;
    transition: color 600ms cubic-bezier(0.48, 0, 0.12, 1);
    z-index: 10;
  }

  .button-57 span:last-child {
    color: white;
    display: flex;
    position: absolute;
    bottom: 0;
    transition: all 500ms cubic-bezier(0.48, 0, 0.12, 1);
    z-index: 100;
    opacity: 0;
    top: 50%;
    left: 50%;
    transform: translateY(225%) translateX(-50%);
    height: 30px;
    line-height: 30px;
  }

  .button-57:after {
    content: "";
    position: absolute;
    bottom: -50%;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    transform-origin: bottom center;
    transition: transform 600ms cubic-bezier(0.48, 0, 0.12, 1);
    transform: skewY(9.3deg) scaleY(0);
    z-index: 50;
  }

  .button-57:hover:after {
    transform-origin: bottom center;
    transform: skewY(9.3deg) scaleY(2);
  }

  .button-57:hover span:last-child {
    transform: translateX(-50%) translateY(-100%);
    opacity: 1;
    transition: all 900ms cubic-bezier(0.48, 0, 0.12, 1);
  }
}

@media screen and (min-width: 3840px) {
  .placeAndWL {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    display: none;
  }

  .discord4 {
    display: none;
  }

  /*.............. FAQ ..............*/

  summary {
    font-size: 30px;
    font-weight: 600;
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;

    border: 2px solid #414141;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 70%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .faq__content {

    color: rgba(255, 255, 255, 0.829);
    padding-bottom: 20px;
    text-align: left;
    font-size: 25px;
  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 90px;
    color: white;
    text-shadow: 0 0 5px #ffffff;
  }

  .faqbg {
    width: 100%;
    text-align: left;
    padding: 2px;
    padding-bottom: 20px;
    margin-right: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: 100%;
    padding-top: 5%;
    padding-bottom: 10%;
  }

  .writebtn {
    background-color: #53535385;
    color: white;
    padding: 10px;
    border-radius: 10px;
    border: none;
  }


  .mintingAmount {
    font-size: 20px;
    padding: 10px;

  }

  .adH {
    font-size: 40px;
    color: white;
    text-align: center;
    font-weight: bold;
  }

  .boxWrap6 {
    margin-top: 5%;
  }

  .osPic {
    width: 50px;
    height: 50px;
    cursor: pointer;

  }

  .price {
    text-align: center;
    font-size: 50px;
    color: #ffffff;
  }

  .discord {
    display: none;
  }

  .discord3 {
    padding-right: 20px;
    display: none;
  }


  .uRMobile {
    display: none;
  }

  .storyPicDivMobile {
    display: none;
  }

  .allWrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
  }

  .boxWrap2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #0000003f;
    padding-top: 5%;
    padding-bottom: 5%;
    border-radius: 20%;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .boxWrap3 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 10%;
    margin-bottom: 10%;
  }

  .storyPicDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .storyH {
    font-size: 50px;
    font-weight: bold;
    text-shadow: 4px 3px 0 #383d6e62;
    color: white;
    margin-bottom: 10px;
    margin-top: 10%;
  }

  .storyCon {
    color: white;
    font-size: 22px;
    text-shadow: 0 0 10px #ffffff4b;
  }

  .storyPic {
    width: 200px;
    height: 250px;
    border: 2px solid #d423b7;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 10px #d64dbf;
  }

  .storyRight {
    width: 90%;
    text-align: center;
    margin-top: 5%;
    margin-left: auto;
    margin-right: auto;
  }

  .uR {
    width: 50%;
  }

  .intro {
    color: white;
    font-size: 170px;
    font-family: 'Russo One', sans-serif;
    margin-bottom: 5%;
  }

  .intro2 {
    color: rgba(255, 255, 255, 0.918);
    font-size: 60px;
    text-shadow: 4px 3px 0 #383d6e18;
    margin-top: -5%;
    text-align: justify;
    line-height: 1.5;
  }

  .mintDiv {

    width: 40%;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 5px dashed white;
  }

  .errorMessage {

    font-size: 20px;
    color: #fc534d;
    text-align: center;

  }

  .loadingContainer {}

  .loadingText {
    font-size: 30px;
    text-align: center;
    color: rgb(204, 204, 204);
  }

  .loadTextSub {
    text-align: center;
    color: rgb(185, 185, 185);
    font-size: 13px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;

  }

  .wPMobile {
    display: none;
  }

  .introductionMobile {
    display: none
  }

  .hl {
    color: #6ce34d;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 20px;
    padding-top: 20px;
    justify-content: space-evenly;
    width: max-content;
    margin-top: auto;
    margin-bottom: auto;
  }

  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-left: auto;
    margin-right: auto;
    width: 5%;
    justify-content: space-between;
  }

  .discord2 {
    padding-right: 20px;
    transition: transform .2s;
    margin-top: auto;
    margin-bottom: auto;
  }

  .discord2:hover {
    transform: scale(1.1);
  }


  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }



  .note {
    color: rgb(255, 255, 255);
  }

  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;

  }

  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/

  }

  /*.website{
  margin-top: 10px;
  margin-left: 10px;
 /* z-index: -2;*/
  /*color: #ffffffa2;
}*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;

  }





  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;

    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);


  }

  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: 'Poppins', sans-serif;
    padding-top: 5px;
    margin-left: 10%;

  }

  .connect2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-top: 5px;

  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Poppins', sans-serif;
    color: white;
    padding-top: 15px;
    font-size: 16px;


  }

  .connect div:hover {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Poppins', sans-serif;
    color: rgb(184, 184, 184);
    padding-top: 15px;
    cursor: pointer;

  }


  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }




  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;

  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    height: max-content;
    margin-top: 5%;
  }

  .nftminter2 {
    color: yellow;
    font-size: 120px;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    justify-content: center;
  }

  .nftminter {
    background-color: #18181a;
    justify-content: flex-start;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    width: 75px;
    border: none;
    cursor: pointer;
    border: 2px solid #620fb5;

  }

  .btnfos-0-2-2 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    width: 75px;
    border: none;
    cursor: pointer;
    border: 2px solid #620fb5;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }


  .btnfos-0-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) -5px 5px;
  }


  .btnfos-0-2-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) 5px 5px;
  }


  .btnfos-0-3 {
    font-size: 50px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 5px;
    border: 2px solid #620fb5;

    justify-content: center;
    text-align: center;
    width: 330px;
    margin-bottom: 5%;
    margin-top: 10%;
  }

  .btnfos-0-4 {
    font-size: 50px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 5px;
    border: 2px solid rgb(255, 255, 255);
    background-color: #620fb5;
    justify-content: center;
    text-align: center;
    width: fit-content;
    margin-bottom: 5%;
    color: white;
    margin-top: 5%;
    width: fit-content;

  }


  .btnfos-0-3:hover {

    color: rgb(78, 78, 78);
    cursor: pointer;

  }

  .btnfos-0-3:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(141, 141, 141, 0.288) 0px 5px;
  }

  .nftamount {
    color: rgb(255, 174, 0);
    font-size: 120px;
    text-shadow: 4px 3px 0 #383d6e17;
  }

  .walletConnect {
    display: flex;
    margin-top: 2%;
  }

  .connectButton {

    font-size: 45px;
  }

  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;

  }

  .loadingContainer {
    text-align: center;
  }

  .successfully {
    text-align: center;
    color: rgb(124, 199, 74);
    font-size: 30px;
  }


  .headers {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding-top: 12px;
    margin-left: auto;
    margin-right: auto;

    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/

  }

  .headers2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-bottom: 12px;
    padding-top: 12px;
    width: 90%;

    margin-left: auto;
    margin-right: auto;


    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/

  }


  .introduction {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: 2%;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .wallet2 {
    width: 350px;
    padding: 5px;
    transition: transform .3s;
    font-size: 35px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    background-color: #efb03a00;
    border: 2px solid #ac01ac;
    color: white;
  }

  .wallet2:hover {
        background-color: rgba(255, 255, 255, 0.103);

  }

  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }

  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }


  .wallet3 {

    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 100px;
    padding-left: 50px;
    padding-right: 50px;
    align-items: center;
    background: rgb(92, 1, 91);
    background: linear-gradient(354deg, rgba(92, 1, 91, 1) 1%, rgba(75, 12, 140, 1) 61%);
    border: 0;
    box-sizing: border-box;
    box-sizing: border-box;
    color: white;
    display: flex;
    transition: transform .5s;
    font-family: 'Russo One', sans-serif;

  }

  .wallet3:hover {
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
    transform: scale(1.2);
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  .in2 {
    width: 50%;
    text-align: left;
    margin-top: auto;
    margin-bottom: auto;
  }

  .totalSupply {
    text-align: center;
    font-size: 100px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    text-shadow: 4px 3px 0 #383d6e3a;
    letter-spacing: 1px;
    font-family: 'Russo One', sans-serif;
  }

  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }

    10%,
    87.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    20%,
    92.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    25%,
    95% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  .logoPic {
    border-radius: 50%;
    width: 150px;
    height: 150px;
    border: 2px solid #7212c0;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 10px #780594;
  }

  .logo {
    margin-top: auto;
    margin-bottom: auto;
  }

  .footer {
    background-color: rgba(0, 0, 0, 0);
    padding: 2%;
    position: static;
    margin-top: 10%;
  }

  .copyright {
    color: rgba(255, 255, 255, 0.712);
    text-align: center;
    font-size: 50px;
    text-decoration: underline;
  }

  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }

  .light2 {
    height: 200vh;
  }

  .light {
    background: radial-gradient(circle, rgba(0, 0, 0, 0.6642254313834909) 1%, rgba(0, 0, 0, 0.9471385966495973) 49%, rgba(0, 0, 0, 0.9247296330641632) 100%), url('./assets/bg.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100vh;
    height: max-content;
    overflow: hidden;
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }

    100% {
      transform: translateX(0);
    }
  }

  @keyframes Slide_Left_Right {

    0%,
    100% {
      transform: translateX(100px);
    }

    12.5%,
    75% {
      transform: translateX(0);
    }

    25% {
      transform: translateX(20px);
    }

  }

  @keyframes Opacity {

    0%,
    100% {
      opacity: 0;
    }

    25%,
    75% {
      opacity: 1;
    }


  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }

  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }

    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }

    50% {
      transform: scale(1.05, 1.05);
    }

    100% {
      transform: scale(1, 1);
    }
  }

  .beast {
    width: 1100px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
    border: white 2px solid;
    border: 2px solid #7212c0;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 10px #780594;

  }

  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }

    50% {
      transform: scale(1.05, 1.05);
    }

    100% {
      transform: scale(1, 1);
    }
  }

  /* CSS */
  .button-57 {
    position: relative;
    overflow: hidden;
    border: 1px solid #18181a;
    color: #ffffff;
    display: inline-block;
    font-size: 70px;
    line-height: 15px;
    padding: 50px 50px 50px;
    text-decoration: none;
    cursor: pointer;
    background: rgba(240, 97, 221, 0.171);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    letter-spacing: 3px;

  }

  .button-57 span:first-child {
    position: relative;
    transition: color 600ms cubic-bezier(0.48, 0, 0.12, 1);
    z-index: 10;
  }

  .button-57 span:last-child {
    color: white;
    display: flex;
    position: absolute;
    bottom: 0;
    transition: all 500ms cubic-bezier(0.48, 0, 0.12, 1);
    z-index: 100;
    opacity: 0;
    top: 50%;
    left: 50%;
    transform: translateY(225%) translateX(-50%);
    height: 30px;
    line-height: 30px;
  }

  .button-57:after {
    content: "";
    position: absolute;
    bottom: -50%;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    transform-origin: bottom center;
    transition: transform 600ms cubic-bezier(0.48, 0, 0.12, 1);
    transform: skewY(9.3deg) scaleY(0);
    z-index: 50;
  }

  .button-57:hover:after {
    transform-origin: bottom center;
    transform: skewY(9.3deg) scaleY(2);
  }

  .button-57:hover span:last-child {
    transform: translateX(-50%) translateY(-100%);
    opacity: 1;
    transition: all 900ms cubic-bezier(0.48, 0, 0.12, 1);
  }
}